import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

let routes = [
	// {
	// 	path: '/',
	// 	redirect: '/index'
	// },
	{
		path: '/',
		name: 'index',
		component: () => import(/* index */ '@/views/index.vue'),
		meta: { title: '首页' },
	},
	{
		path: '/experience',
		name: 'experience',
		component: () => import(/* experience */ '@/views/experience.vue'),
		meta: { title: '0元体验' },
	},
	{
		path: '/aboutUs',
		name: 'aboutUs',
		component: () => import(/* aboutUs */ '@/views/aboutUs.vue'),
		meta: { title: '关于我们' },
	},
	{
		path: '/aptitude',
		name: 'aptitude',
		component: () => import(/* aptitude */ '@/views/aptitude.vue'),
		meta: { title: '审核' },
	}
];

const router = new VueRouter({
	mode: 'history',
	routes,
});

export default router;
